import { connect } from 'react-redux';
import { RootState } from 'store/reducers';
import { Breadcrumbs, TextEditor } from 'components';
import { CardBody, Row, Col, Card, Input, Button } from 'reactstrap';
import React, { ChangeEvent, useEffect, useState } from 'react';
import DeleteTemplateModal from './components/DeleteTemplateModal/DeleteTemplateModal';
import { IEditTemplates, TIsModalOpen } from './EditTemplatesTypes';
import EditTemplateModal from './components/EditBlockModal/EditBlockModal';
import {withRouter} from 'react-router-dom';
import { Prompt, useParams } from 'react-router';
import {
  setSelectedItemId,
  updateTemplate,
  addTemplate,
  deleteTemplate,
  updateBlock,
  addBlock,
  deleteBlock,
  setIsTextChanged,
} from 'store/actions';
import {
  IDocTemplateAdd,
  IDocTemplateBlockGet,
} from 'store/document/types';
import {UNSAVED_UPDATE_MESSAGE} from "constants/app_сonstants";

function EditTemplates({
  history,
  setSelectedItemId,
  updateTemplate,
  addTemplate,
  deleteTemplate,
  currentItem,
  addBlock,
  updateBlock,
  createdBlocks,
  deleteBlock,
  isTextChanged,
  setIsTextChanged
}: IEditTemplates) {
  // Текст редактора шаблона
  let {templateId}  = useParams<{templateId: string | undefined}>()

  const [initialText, setInitialText] = useState(currentItem?.content || '');
  const [documentTitle, setDocumentTitle] = useState('');
  const [isModalOpen, setIsModalOpen] = useState<TIsModalOpen>(false);

  const [isBlock, setIsBlock] = useState(false);
  const [itemBlock, setItemBlock] = useState<IDocTemplateBlockGet | null>(null);
  // Существующий шаблон или нет
  const [isEdit, setIsEdit] = useState<boolean>(Boolean(currentItem?.id));
  const [editor, setEditor] = useState<any>(null);

  useEffect(() => {
    function beforeunloadHandler(event) {
      if (isTextChanged) {
        event.returnValue = UNSAVED_UPDATE_MESSAGE;
      }
    }

    window.addEventListener('beforeunload', beforeunloadHandler);

    return () => {
      window.removeEventListener('beforeunload', beforeunloadHandler)
    }
  }, [isTextChanged, editor])

  useEffect(() => {
    if (currentItem && !templateId) {
      setSelectedItemId(null, true)
    } else if (!currentItem && templateId) {
      setSelectedItemId(parseInt(templateId), true)
    }
  }, [setSelectedItemId, currentItem, templateId])

  useEffect(() => {
    setInitialText(currentItem?.content || '')
    setDocumentTitle(currentItem?.title || '')
  }, [setInitialText, setDocumentTitle, currentItem]);

  useEffect(() => {
    setIsEdit(Boolean(currentItem))
  }, [setIsEdit, currentItem])

  const templateTitleChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setDocumentTitle(e.target.value);
    if (!isTextChanged) {
      setIsTextChanged(true)
    }
  };

  // Удаление шаблона
  function handleDeleteDocumentClick() {
    setIsBlock(false);
    setIsModalOpen('delete');
  }

  // Удаление блока
  function handleDeleteBlockClick(blockName) {
    setIsBlock(true);
    setItemBlock(blockName);
    setIsModalOpen('delete');
  }

  // Редактирование блока
  function handleEditBlockClick(blockName = null) {
    setIsBlock(true);
    setItemBlock(blockName);
    setIsModalOpen('edit');
  }

  // Сохранение шаблона
  function handleDocumentSave() {
    templateSave();
    return history.push('/transcription/data')
  }

  // Отмена редактирования
  function handleCancelClick() {
    if (isTextChanged) {
      const isClose = window.confirm(UNSAVED_UPDATE_MESSAGE)

      if (isClose) {
        setIsTextChanged(false)
        return history.push('/transcription/data')
      }
    } else {
      return history.push('/transcription/data')
    }
  }

  // Если шаблон только создается сохранять нужно блоки тоже
  function templateSave() {
    if (isEdit && currentItem?.template_id) {
      const newTemplate: IDocTemplateAdd = {
        ...currentItem,
        title: documentTitle,
        content: editor.getData(),
      };
      updateTemplate(newTemplate);
    } else {
      const newTemplate: IDocTemplateAdd = {
        title: documentTitle,
        content: editor.getData(),
      };
      addTemplate(newTemplate);
    }
  }

  return (
    <>
      <Prompt
        message={(location, action) => {
          if (!location.pathname.includes('/transcription/') || action === 'POP') {
            return UNSAVED_UPDATE_MESSAGE
          } else {
            return true
          }
        }}
        when={isTextChanged}
      />
      <Breadcrumbs
        title={`${isEdit ? 'Редактирование шаблона' : 'Добавление шаблона'}`}
        breadcrumbItems={[
          'Дела судебного участка',
          `${isEdit ? 'Редактирование шаблона' : 'Добавление шаблона'}`,
        ]}
      />
      <Row>
        <Col xs='12'>
          <Card>
            <CardBody>
              <div className='editor__controls mb-4'>
                <div className='editor__controls__left template__header'>
                  <h4 className='me-3 mb-0 card-title template__title'>
                    Название документа:
                  </h4>
                  <div className='template__title_input_wrapper'>
                    <i className='fas fa-pen template__input_img_edit' />
                    <Input
                      className='template__title_input'
                      value={documentTitle}
                      onChange={templateTitleChangeHandler}
                    />
                  </div>
                </div>
                <div className='editor__buttons-container'>
                  <Button
                    className='template__button template__cancel_button'
                    onClick={handleCancelClick}
                  >
                    Отмена
                  </Button>
                  {isEdit && (
                    <>
                      <Button
                        color='primary'
                        className='template__button template__delete_button'
                        onClick={handleDeleteDocumentClick}
                      >
                        Удалить
                      </Button>
                    </>
                  )}
                  <>
                    <Button
                      className='template__button template__save_button'
                      color='primary'
                      onClick={handleDocumentSave}
                    >
                      Сохранить
                    </Button>
                  </>
                </div>
              </div>
              <TextEditor
                initialText={initialText}
                handleDeleteBlockClick={handleDeleteBlockClick}
                handleEditBlockClick={handleEditBlockClick}
                blocks={createdBlocks}
                editor={editor}
                setEditor={setEditor}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <DeleteTemplateModal
        condition={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        itemName={isBlock ? itemBlock?.title : currentItem?.title || ''}
        itemId={isBlock ? itemBlock?.id : currentItem?.template_id}
        isBlock={isBlock}
        isTemplate={true}
        deleteHandler={isBlock ? deleteBlock : deleteTemplate}
      />
      <EditTemplateModal
        condition={isModalOpen}
        updateBlock={updateBlock}
        addBlock={addBlock}
        setisModalOpen={setIsModalOpen}
        item={itemBlock}
        setItem={setItemBlock}
      />
    </>
  );
}

const mapStateToProps = (state: RootState) => {
  const { currentItem, selectedItemId, createdBlocks, isTextChanged } = state.Document

  return {
    currentItem,
    selectedItemId,
    createdBlocks,
    isTextChanged
  };
};

export default connect(mapStateToProps, {
  setSelectedItemId,
  updateTemplate,
  addTemplate,
  deleteTemplate,
  updateBlock,
  addBlock,
  deleteBlock,
  setIsTextChanged
})(withRouter(EditTemplates));
