export const MESSAGE_BY_HEADER = {
  200: 'Запрос выполнен успешно',
  400: 'Запрос не сформирован должным образом',
  401: 'Указанные имя пользователя и пароль не верны',
  403: 'Имя пользователя и пароль недействительны. Пожалуйста, введите правильный логин и пароль',
  404: 'Указанный в запросе ресурс не найден',
  500: 'Внутренняя ошибка HTTP-сервера',
  501: 'Запрос не реализован HTTP-сервером',
  502: 'Указанные имя пользователя и пароль не верны',
};

export const CODE_ACCESS_IS_DENIED = 401;
