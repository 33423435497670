import {call, put, select, take, takeEvery, takeLatest} from 'redux-saga/effects';
import {
  ActionType,
  IAddDocument,
  IAddTemplate,
  IDeleteDocument,
  IDeleteTemplate,
  IFetchDocTemplate,
  IUpdateDocument,
  IUpdateTemplate
} from './actionTypes';
import TranscriptionDocuments from './services';
import {AxiosResponse} from 'axios';
import {IResponse} from 'models/response';
import {IDocTemplate} from './types';
import {
  getCreatedBlocks,
  getDataHearing,
  getHearingId,
  getIsTemplate,
  getSelectedItemId,
  getUserId
} from 'store/selectors';
import {
  clearBlocks,
  fetchDocTemplate,
  fetchDocTemplates,
  getInfoJudicialHearing as getInfoJudicialHearingAction,
  setCurrentItem,
  setSelectedItemId as setSelectedItemIdAction,
  setTemplates
} from 'store/actions';
import {setActionStatus} from "store/actionStatus/actions";
import {getMessageFromError, isString} from "utils/app_helper";

export function* updateCurrentItem() {
  try {
    const selectedItemId = yield select(getSelectedItemId)
    const isTemplate = yield select(getIsTemplate)
    if (!selectedItemId) {
      yield put(setCurrentItem(undefined, true))
    } else if (isTemplate) {
      yield put(fetchDocTemplate(selectedItemId))
    } else {
      yield call(updateSelectedDocument, selectedItemId)
    }
    yield put(clearBlocks())
  } catch (e) {
    yield put(setSelectedItemIdAction(null, true))
    console.error(e)
  }
}

// ======== Шаблоны ========
export function* fetchDocTemplatesSaga() {
  try {
    const userId = yield select(getUserId)

    const res: AxiosResponse<IResponse<IDocTemplate[]>> = yield call(
      TranscriptionDocuments.fetchDocTemplates,
      userId,
    );

    yield put(setTemplates(res.data.data));
  } catch (e: any) {
    if (e.response.status === 404) {
      yield put(setTemplates([]))
    } else {
      yield put(
        setActionStatus({
          message: getMessageFromError(e),
          status: 'error',
        }),
      );
    }
  }
}

export function* fetchDocTemplateSaga({payload}: IFetchDocTemplate) {
  try {
    const res: AxiosResponse<IResponse<IDocTemplate>> = yield call(
      TranscriptionDocuments.fetchDocTemplate,
      payload
    );
    yield put(setCurrentItem(res.data.data, true));
  } catch (e: any) {
    // Если шаблон не найден, обновляем список шаблонов
    if (e.response.status === 404) {
      yield put(fetchDocTemplates())
    } else {
      yield put(
        setActionStatus({
          message: getMessageFromError(e),
          status: 'error',
        })
      )
    }
    yield put(setSelectedItemIdAction(null, true))
  }
}

export function* addTemplate({payload}: IAddTemplate) {
  try {
    const userId = yield select(getUserId)
    const createdBlocks = yield select(getCreatedBlocks)

    const res: AxiosResponse<IResponse<any>> = yield call(
      TranscriptionDocuments.addDocTemplate,
      {...payload, userId, createdBlocks},
    );

    yield put(fetchDocTemplates())
    yield take('SET_TEMPLATES')
    yield put(setSelectedItemIdAction(res.data.data.template_id, true));
  } catch (e) {
    yield put(
      setActionStatus({
        message: getMessageFromError(e),
        status: 'error',
      })
    )
  }
}

export function* updateTemplate({payload}: IUpdateTemplate) {
  try {
    const createdBlocks = yield select(getCreatedBlocks)

    yield call(
      TranscriptionDocuments.updateDocTemplate,
      payload,
    );

    for (let block of createdBlocks) {
      if (block.id > 0) {
        yield call(
          TranscriptionDocuments.updateDocTemplate,
          {...block, template_id: block.id},
        );
      } else {
        yield call(
          TranscriptionDocuments.addDocTemplate,
          {...block, templateId: payload.template_id}
        );
      }
    }

    if (payload.blocks) {
      for (let templateBlock of payload.blocks) {
        if (!createdBlocks.find(block => block.id === templateBlock.id)) {
          yield call(TranscriptionDocuments.deleteDocTemplate, templateBlock.id);
        }
      }
    }
    yield put(fetchDocTemplates())
    yield put(setSelectedItemIdAction(payload.template_id, true));
  } catch (e) {
    yield put(
      setActionStatus({
        message: getMessageFromError(e),
        status: 'error',
      })
    )
  }
}

export function* deleteDocTemplate({payload}: IDeleteTemplate) {
  try {
    yield call(TranscriptionDocuments.deleteDocTemplate, payload);

    yield put(fetchDocTemplates())
    yield put(setSelectedItemIdAction(null, true))
  } catch (e) {
    yield put(
      setActionStatus({
        message: getMessageFromError(e),
        status: 'error',
      })
    )
  }
}

// ===== Документы ======
function* addDocxFile({payload: data}: IAddDocument) {
  try {
    const hearingId = yield select(getHearingId);
    const clearedFileName = data.name.replaceAll('"', "'");
    const res: AxiosResponse<IResponse<any>> = yield call(TranscriptionDocuments.addDocxFile, {
      ...data,
      hearingId: hearingId,
      fileName: clearedFileName,
    });
    yield put(getInfoJudicialHearingAction());
    yield take('SET_INFO_HEARING')
    yield put(setSelectedItemIdAction(res.data.data.id, false))
    yield put(
      setActionStatus({
        message: 'Файл успешно сохранён',
        status: 'success',
      }),
    );
  } catch (error) {
    yield put(
      setActionStatus({
        message: getMessageFromError(error),
        status: 'error',
      }),
    );
  }
}

function* updateDocumentText({payload}: IUpdateDocument) {
  try {
    if (isString(payload.content)) {
      yield call(TranscriptionDocuments.updateDocumentText, {
        text: payload.content,
        id: payload.id,
      });

      yield put(
        setActionStatus({
          message: 'Документ успешно сохранён',
          status: 'success',
        }),
      );
    }
  } catch (e) {
    yield put(
      setActionStatus({
        message: getMessageFromError(e),
        status: 'error',
      }),
    );
  }
}

function* deleteDocument({payload}: IDeleteDocument) {
  try {
    yield call(TranscriptionDocuments.deleteDocument, payload);

    yield put(getInfoJudicialHearingAction())
    yield take('SET_DOCUMENTS')
    yield put(setSelectedItemIdAction(null, true))
  } catch (e) {
    yield put(
      setActionStatus({
        message: getMessageFromError(e),
        status: 'error',
      })
    )
  }
}

function* updateSelectedDocument(documentId: number) {
  try {
    const hearingData = yield select(getDataHearing);
    if (hearingData.documents) {
      for (let doc of hearingData.documents) {
        if (doc.id === documentId || hearingData.documents.length === 1) {
          const response = yield call(TranscriptionDocuments.getHtmlFile, doc.path);
          let docBlob = new Blob([response.data], {type: 'text/html'});
          let htmlString = yield docBlob.text();
          yield put(setCurrentItem({...doc, content: htmlString}, false));
          break;
        }
      }
    }
  } catch (e) {
    yield put(setSelectedItemIdAction(null, true))
    yield put(
      setActionStatus({
        message: getMessageFromError(e),
        status: 'error',
      })
    )
  }
}

function* DocumentSaga() {
  yield takeEvery(ActionType.FETCH_DOC_TEMPLATES, fetchDocTemplatesSaga);
  yield takeEvery(ActionType.FETCH_DOC_TEMPLATE, fetchDocTemplateSaga);
  yield takeLatest(ActionType.SET_SELECTED_ITEM_ID, updateCurrentItem);
  yield takeEvery(ActionType.ADD_TEMPLATE, addTemplate);
  yield takeEvery(ActionType.UPDATE_TEMPLATE, updateTemplate);
  yield takeEvery(ActionType.DELETE_TEMPLATE, deleteDocTemplate);
  yield takeEvery(ActionType.ADD_DOCUMENT, addDocxFile);
  yield takeEvery(ActionType.UPDATE_DOCUMENT, updateDocumentText);
  yield takeEvery(ActionType.DELETE_DOCUMENT, deleteDocument);
}

export default DocumentSaga;
