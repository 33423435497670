import axiosApi from 'utils/api_helper';
import {SERVER_URL, ENDPOINTS, SERVER_HOST, SERVER_PORT} from 'constants/api_endpoints';
import {IDataLoadDocxFileRequest} from "store/document/types";

export default class TranscriptionDocuments {
  // ====== Шаблоны ======
  static async fetchDocTemplates(userId: number) {
    return axiosApi.get(`${SERVER_URL}/${ENDPOINTS.DOC_TEMPLATES}/user/${userId}`);
  }

  static async fetchDocTemplate(templateId: number) {
    return axiosApi.get(`${SERVER_URL}/${ENDPOINTS.DOC_TEMPLATES}/show/${templateId}`);
  }

  static async addDocTemplate(item) {
    let formData = new FormData();
    let blobContent =  new Blob([item.content], {type: 'text/html'})
    formData.append('user_id', item.userId);
    formData.append('content', blobContent);
    formData.append('title', item.title);

    if (item.createdBlocks) {
      for (let i = 0; i < item.createdBlocks.length; i++) {
        const element = item.createdBlocks[i];
        const elementBlobContent = new Blob([element.content], {type: 'text/html'})
        formData.append(`block_${i + 1}_title`, element.title);
        formData.append(`block_${i + 1}_content`, elementBlobContent);
      }
    }

    if (item.templateId) {
      formData.append('template_id', item.templateId);
    }
    return axiosApi.post(
      `${SERVER_URL}/${ENDPOINTS.DOC_TEMPLATES}/add`,
      formData,
    );
  }
  static async updateDocTemplate(item) {
    let formData = new FormData();
    let blobContent =  new Blob([item.content], {type: 'text/html'})
    formData.append('content', blobContent);
    formData.append('title', item.title);
    formData.append('template_id', item.template_id);
    return axiosApi.post(
      `${SERVER_URL}/${ENDPOINTS.DOC_TEMPLATES}/update`,
      formData,
    );
  }
  static async deleteDocTemplate(itemId) {
    return axiosApi.delete(
      `${SERVER_URL}/${ENDPOINTS.DOC_TEMPLATES}/delete/${itemId}`,
    );
  }

  // ======== Документы =========
  static async getHtmlFile(path: string) {
    return axiosApi.get(
      `${SERVER_HOST}:${SERVER_PORT}/${path}`,
      {headers: {
          "Cache-Control": "no-cache"
        }}
    )
  }

  static async addDocxFile(data: IDataLoadDocxFileRequest) {
    let formData = new FormData();
    let fileBlob = new Blob([data.file], { type: 'text/html' });
    formData.append('file', fileBlob);
    formData.append('meeting_id', data.hearingId);
    formData.append('file_name', data.fileName);
    formData.append('type', data.type);
    return axiosApi.post(`${SERVER_URL}/${ENDPOINTS.FILE}/upload`, formData);
  }

  static async updateDocumentText({text, id} : {text: string, id: number}) {
    const blobText = new Blob([text], {type: 'text/html'});
    let formData = new FormData();
    formData.append('document', blobText);
    return axiosApi.post(
      `${SERVER_URL}/${ENDPOINTS.DOCUMENTS}/update/${id}`, formData
    )
  }

  static async deleteDocument(id : number) {
    return axiosApi.delete(
      `${SERVER_URL}/${ENDPOINTS.DOCUMENTS}/delete/${id}`
    )
  }
}
