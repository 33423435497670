import React from 'react';
import { Label, Button, Modal } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import logoLightSvg from 'assets/images/logo-light.svg';
import LegalInputs from './LegalInputs/LegalInputs';
import RealInputs from './RealInputs/RealInputs';
import { ITEMS_FORM } from './ModalWindowConstants';
import { getInitData, getСompletedForRequestData } from './ModalWindowHelper';
import { validationSchema } from './modalWindowValidation';
import { IModalWindowProps } from './ModalWindowTypes';

const ModalWindow = ({
  closeHandler,
  submitHandler,
  caseData,
  areaId,
  isOpen,
}: IModalWindowProps) => {
  const isChange = Boolean(caseData);
  const initialFormData = getInitData({ ...caseData, areaId });

  const requestConstructor = async (values, errors) => {
    let isValid = !errors.length;
    let requestData = getСompletedForRequestData(values);

    if (isValid) {
      submitHandler(requestData);
      closeHandler();
    }
  };

  return (
    <Modal
      className='cases-modal'
      toggle={closeHandler}
      isOpen={isOpen}
      centered
    >
      <div className='modal-closebtn' onClick={closeHandler}>
        <i className='fas fa-times' />
      </div>
      <div className='bg-primary bg-soft cases-modal__header modal-header__narrow'>
        <div className='text-primary cases-modal__header__heading-container'>
          <h3 className='text-primary'>
            {isChange ? ITEMS_FORM.alterTitle : ITEMS_FORM.title}
          </h3>
        </div>
        <div className='auth-logo-header cases-modal__header__logo'>
          <span className='logo-lg'>
            <img src={logoLightSvg} alt='' height='50' />
          </span>
        </div>
      </div>
      <div className='pt-0'>
        <div className='form-cardBody'>
          <Formik
            enableReinitialize={true}
            initialValues={initialFormData}
            onSubmit={(values, errors) => {
              requestConstructor(values, errors);
            }}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({ values, errors }) => (
              <Form className='form-horizontal'>
                <div className='formGroup'>
                  <div className='Row form-field'>
                    <Label className='Col' htmlFor='uid'>
                      УИД
                    </Label>
                    <Field
                      className={`Col form-control ${
                        errors.uid ? 'is-invalid' : ''
                      }`}
                      id='uid'
                      name='uid'
                    />
                    <div />
                    <ErrorMessage
                      name='uid'
                      className='invalid-feedback'
                      component='div'
                    />
                  </div>
                  <div className='Row form-field'>
                    <Label className='Col' htmlFor='case_id'>
                      Номер
                    </Label>
                    <Field
                      className={`Col form-control ${
                        errors.case_id ? 'is-invalid' : ''
                      }`}
                      id='case_id'
                      name='case_id'
                    />
                    <div />
                    <ErrorMessage
                      name='case_id'
                      className='invalid-feedback'
                      component='div'
                    />
                  </div>
                </div>
                <div className='formGroup'>
                  <div className='Row form-field'>
                    <Label className='Col bold' htmlFor='start'>
                      Дата:
                    </Label>
                    <Field
                      type='date'
                      className={`Col form-control ${
                        errors.start ? 'is-invalid' : ''
                      }`}
                      id='start'
                      name='start'
                    />
                    <div />
                    <ErrorMessage
                      name='start'
                      className='invalid-feedback'
                      component='div'
                    />
                  </div>
                </div>
                <div className='formGroup'>
                  <div className='Row form-field form-checkbox-row'>
                    <div className='Col bold'>Истец:</div>
                    <div className='Col form-checkbox-container form-check'>
                      <Field
                        type='checkbox'
                        id='isPlaintiffLegal'
                        name='isPlaintiffLegal'
                        className='form-check-input'
                      />
                      <Label
                        for='isPlaintiffLegal'
                        className='form-check-label'
                      >
                        Юридическое лицо
                      </Label>
                    </div>
                  </div>
                  {values.isPlaintiffLegal ? (
                    <LegalInputs isPlaintiff={true} errors={errors} />
                  ) : (
                    <RealInputs isPlaintiff={true} errors={errors} />
                  )}
                </div>
                <div className='formGroup'>
                  <div className='Row form-field form-checkbox-row'>
                    <div className='Col bold'>Ответчик:</div>
                    <div className='Col form-checkbox-container form-check'>
                      <Field
                        type='checkbox'
                        id='isDefendantLegal'
                        name='isDefendantLegal'
                        className='form-check-input'
                      />
                      <Label
                        for='isDefendantLegal'
                        className='form-check-label'
                      >
                        Юридическое лицо
                      </Label>
                    </div>
                  </div>
                  {values.isDefendantLegal ? (
                    <LegalInputs isPlaintiff={false} errors={errors} />
                  ) : (
                    <RealInputs isPlaintiff={false} errors={errors} />
                  )}
                </div>
                <div />
                <div className='formGroup modal-buttons-container'>
                  <Button type='button' onClick={closeHandler}>
                    Отмена
                  </Button>
                  <Button type='submit' color='primary'>
                    Сохранить
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default ModalWindow;
