import { Field, useFormikContext } from 'formik';
import { Label } from 'reactstrap';
import React, { useEffect } from 'react';

export default function RealInputs({ isPlaintiff, errors }) {
  let prefix = isPlaintiff ? 'plaintiffReal' : 'defendantReal';
  const { values, setFieldValue }: any = useFormikContext();

  useEffect(() => {
    if (values.plaintiffReal.same_mail) {
      setFieldValue(
        'plaintiffReal.res_address',
        values.plaintiffReal.reg_address,
      );
    }
    if (values.defendantReal.same_mail) {
      setFieldValue(
        'defendantReal.res_address',
        values.defendantReal.reg_address,
      );
    }
  }, [
    values.plaintiffReal.reg_address,
    values.plaintiffReal.res_address,
    values.defendantReal.reg_address,
    values.defendantReal.res_address,
    values.plaintiffReal.same_mail,
    values.defendantReal.same_mail,
    setFieldValue,
  ]);

  return (
    <>
      <div className='Row form-field'>
        <Label className='Col' htmlFor={`${prefix}.lastname`}>
          Фамилия
        </Label>
        <Field
          className={`Col form-control ${
            errors[prefix] ? (errors[prefix].lastname ? 'is-invalid' : '') : ''
          }`}
          id={`${prefix}.lastname`}
          name={`${prefix}.lastname`}
        />
        <div />
        <div className='invalid-feedback'>
          {errors[prefix] ? errors[prefix].lastname : ''}
        </div>
      </div>
      <div className='Row form-field'>
        <Label className='Col' htmlFor={`${prefix}.name`}>
          Имя
        </Label>
        <Field
          className={`Col form-control ${
            errors[prefix] ? (errors[prefix].name ? 'is-invalid' : '') : ''
          }`}
          id={`${prefix}.name`}
          name={`${prefix}.name`}
        />
        <div />
        <div className='invalid-feedback'>
          {errors[prefix] ? errors[prefix].name : ''}
        </div>
      </div>
      <div className='Row form-field'>
        <Label className='Col' htmlFor={`${prefix}.surname`}>
          Отчество
        </Label>
        <Field
          className={`Col form-control ${
            errors[prefix] ? (errors[prefix].surname ? 'is-invalid' : '') : ''
          }`}
          id={`${prefix}.surname`}
          name={`${prefix}.surname`}
        />
        <div />
        <div className='invalid-feedback'>
          {errors[prefix] ? errors[prefix].surname : ''}
        </div>
      </div>
      <div className='Row form-field'>
        <Label className='Col' htmlFor={`${prefix}.birthday`}>
          Дата рождения
        </Label>
        <Field
          type='date'
          className={`Col form-control ${
            errors[prefix] ? (errors[prefix].birthday ? 'is-invalid' : '') : ''
          }`}
          id={`${prefix}.birthday`}
          name={`${prefix}.birthday`}
        />
        <div />
        <div className='invalid-feedback'>
          {errors[prefix] ? errors[prefix].birthday : ''}
        </div>
      </div>
      <div className='Row form-field'>
        <Label className='Col' htmlFor={`${prefix}.reg_address`}>
          Адрес регистрации
        </Label>
        <Field
          className={`Col form-control ${
            errors[prefix]
              ? errors[prefix].reg_address
                ? 'is-invalid'
                : ''
              : ''
          }`}
          id={`${prefix}.reg_address`}
          name={`${prefix}.reg_address`}
        />
        <div />
        <div className='invalid-feedback'>
          {errors[prefix] ? errors[prefix].reg_address : ''}
        </div>
      </div>
      <div className='Row form-field'>
        <div className='Col' />
        <div className='Col form-checkbox-container form-check'>
          <Field
            type='checkbox'
            id={`${prefix}.same_mail`}
            name={`${prefix}.same_mail`}
            className='form-check-input'
          />
          <Label for={`${prefix}.same_mail`} className='form-check-label'>
            Адрес регистрации совподает с адресом проживания
          </Label>
        </div>
      </div>
      <div className='Row form-field'>
        <Label className='Col' htmlFor={`${prefix}.res_address`}>
          Адрес проживания
        </Label>
        <Field
          className={`Col form-control ${
            errors[prefix]
              ? errors[prefix].res_address
                ? 'is-invalid'
                : ''
              : ''
          }`}
          id={`${prefix}.res_address`}
          name={`${prefix}.res_address`}
          disabled={values[prefix].same_mail}
        />
        <div />
        <div className='invalid-feedback'>
          {errors[prefix] ? errors[prefix].res_address : ''}
        </div>
      </div>
      <div className='Row form-field'>
        <Label className='Col' htmlFor={`${prefix}.phone`}>
          Номер телефона
        </Label>
        <Field
          className={`Col form-control ${
            errors[prefix] ? (errors[prefix].phone ? 'is-invalid' : '') : ''
          }`}
          id={`${prefix}.phone`}
          name={`${prefix}.phone`}
        />
        <div />
        <div className='invalid-feedback'>
          {errors[prefix] ? errors[prefix].phone : ''}
        </div>
      </div>
    </>
  );
}
