import { useFormikContext, Field, FieldArray } from 'formik';
import { Label, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { RootState } from 'store/reducers';
import { IParticipants } from 'store/judicialHearing/types';
import { FormFieldAsSelect } from 'components';
import { PanelColor } from 'modules';
import { ucFirst, getTheFirstLetter } from 'utils/app_helper';
import { IItemFormAsRowProps } from './ItemFormAsRowTypes';
import { LIST_CHANNELS } from './ItemFormAsRowConstants';
import {useEffect, useMemo} from "react";

const ItemFormAsRow = ({
  nameField,
  countChannels,
  isUseDiarization,
}: IItemFormAsRowProps) => {
  const { values, setFieldValue } = useFormikContext<IParticipants[]>();

  let valueItem = useMemo(() => {
    const listPathsToValue = nameField.split('.');
    if (Object.keys(values).length < 1 || !values[listPathsToValue[0]]) return false
    return values[listPathsToValue[0]][listPathsToValue[1]] || {}
  }, [values, nameField])

  useEffect(() => {
    if (valueItem) {
      valueItem.times = valueItem.times.length
        ? valueItem.times
        : [{ timeEnd: '00:00:00', timeStart: '00:00:00' }]
    }
  }, [valueItem])

  if (!valueItem) return null

  function onUpdateIndicatorColor(color: string) {
    setFieldValue(`${nameField}.color`, color);
  }

  const nameParticipant = [
    ' ',
    valueItem.lastname,
    ' ',
    getTheFirstLetter(valueItem.name),
    '.',
    getTheFirstLetter(valueItem.surname),
    '.',
  ].join('');

  return (
    <Row>
      <Col>
        <div className='row'>
          <Label className='col-form-label'>
            <strong>{ucFirst(valueItem.type)}: </strong>
            {nameParticipant}
          </Label>
        </div>
      </Col>
      <Col>
        <FormFieldAsSelect fieldName={`${nameField}.channel`}>
          <>
            {/* <Options listOptions={LIST_CHANNEL} numberDisabledOption={0} /> */}
            <option disabled value={0}>
              Номер {isUseDiarization ? 'спикера' : 'канала'}
            </option>

            {LIST_CHANNELS.slice(0, countChannels || 5).map((channel, item) => (
              <option key={item} value={item + 1}>
                {channel} {isUseDiarization ? 'спикер' : 'канал'}
              </option>
            ))}
          </>
        </FormFieldAsSelect>
      </Col>
      <Col>
        <FieldArray
          name={`${nameField}.times`}
          render={({ insert, remove }) => {
            return (
              <>
                {valueItem.times &&
                  valueItem.times.map((time, index) => (
                    <div key={`${valueItem}_${index}`}>
                      <div className='d-flex align-items-center'>
                        <Field
                          name={`${nameField}.times.${index}.timeStart`}
                          type='time'
                          step='2'
                          className='form-control'
                          format={"ss:mm:hh"}
                        />
                        <i className={`mdi mdi-arrow-right px-2`}></i>
                        <Field
                          name={`${nameField}.times.${index}.timeEnd`}
                          type='time'
                          step='2'
                          className='form-control'
                          format={"ss:mm:hh"}
                        />
                        <i
                          className={`mdi mdi-minus ps-2 controls`}
                          onClick={() => remove(index)}
                        />
                        <i
                          className={`mdi mdi-plus controls`}
                          onClick={() =>
                            insert(index + 1, {
                              timeStart: '00:00:00',
                              timeEnd: '00:00:00',
                            })
                          }
                        />
                      </div>
                      <br />
                    </div>
                  ))}
              </>
            );
          }}
        />

        {/* <br />
        <div className='d-flex align-items-center'>
          <Field
            name={`${nameField}.timeStart`}
            type='time'
            step='2'
            className='form-control'
          />
          <i className={`mdi mdi-arrow-right px-2`} />
          <Field
            name={`${nameField}.timeEnd`}
            type='time'
            step='2'
            className='form-control'
          />
          <i className={`mdi mdi-plus px-2`} onClick={() => alert(2)} />
        </div>
        <br />
        <div className='d-flex align-items-center'>
          <Field
            name={`${nameField}.timeStart`}
            type='time'
            step='2'
            className='form-control'
          />
          <i className={`mdi mdi-arrow-right px-2`} />
          <Field
            name={`${nameField}.timeEnd`}
            type='time'
            step='2'
            className='form-control'
          />
          <i className={`mdi mdi-plus px-2`} onClick={() => alert(3)} />
        </div> */}
      </Col>

      <Col>
        <PanelColor
          selectedColor={valueItem.color}
          changeColor={onUpdateIndicatorColor}
        />
      </Col>
      <Col></Col>
    </Row>
  );
};

const mapStatetoProps = (state: RootState) => {
  const { channels, isUseDiarization } = state.Transcription;
  return {
    countChannels: isUseDiarization ? 5 : channels,
    isUseDiarization,
  };
};

export default connect(mapStatetoProps, {})(ItemFormAsRow);
