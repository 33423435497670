export const LIST_CHANNELS = [
  'Первый ',
  'Второй ',
  'Третий ',
  'Четвертый ',
  'Пятый ',
  'Шестой ',
  'Седьмой ',
  'Восьмой ',
  'Девятый ',
  'Десятый ',
  'Одиннадцатый ',
  'Двенадцатый ',
  'Тринадцатый ',
  'Пятнадцатый  ',
  'Шестнадцатый ',
  'Семнадцатый ',
  'Восемнадцатый ',
  'Девятнадцатый ',
  'Двадцатый ',
];
