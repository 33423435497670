import { Field, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { Label } from 'reactstrap';

export default function LegalInputs({ isPlaintiff, errors }) {
  const { values, setFieldValue }: any = useFormikContext();

  let prefix = isPlaintiff ? 'plaintiffLegal' : 'defendantLegal';

  useEffect(() => {
    if (values.plaintiffLegal.same_mail) {
      setFieldValue(
        'plaintiffLegal.mailing_address',
        values.plaintiffLegal.legal_address,
      );
    }
    if (values.defendantLegal.same_mail) {
      setFieldValue(
        'defendantLegal.mailing_address',
        values.defendantLegal.legal_address,
      );
    }
  }, [
    values.plaintiffLegal.legal_address,
    values.plaintiffLegal.mailing_address,
    values.defendantLegal.legal_address,
    values.defendantLegal.mailing_address,
    values.plaintiffLegal.same_mail,
    values.defendantLegal.same_mail,
    setFieldValue,
  ]);

  return (
    <>
      <div className='Row form-field'>
        <Label className='Col' htmlFor={`${prefix}.inn`}>
          ИНН
        </Label>
        <Field
          className={`Col form-control ${
            errors[prefix] ? (errors[prefix].inn ? 'is-invalid' : '') : ''
          }`}
          id={`${prefix}.inn`}
          name={`${prefix}.inn`}
        />
        <div />
        <div className='invalid-feedback'>
          {errors[prefix] ? errors[prefix].inn : ''}
        </div>
      </div>
      <div className='Row form-field'>
        <Label className='Col' htmlFor={`${prefix}.ogrn`}>
          ОГРН
        </Label>
        <Field
          className={`Col form-control ${
            errors[prefix] ? (errors[prefix].ogrn ? 'is-invalid' : '') : ''
          }`}
          id={`${prefix}.ogrn`}
          name={`${prefix}.ogrn`}
        />
        <div />
        <div className='invalid-feedback'>
          {errors[prefix] ? errors[prefix].ogrn : ''}
        </div>
      </div>
      <div className='Row form-field'>
        <Label htmlFor={`${prefix}.company`}>Название компании</Label>
        <Field
          className={`Col form-control ${
            errors[prefix] ? (errors[prefix].company ? 'is-invalid' : '') : ''
          }`}
          id={`${prefix}.company`}
          name={`${prefix}.company`}
        />
        <div />
        <div className='invalid-feedback'>
          {errors[prefix] ? errors[prefix].company : ''}
        </div>
      </div>
      <div className='Row form-field'>
        <Label className='Col' htmlFor={`${prefix}.legal_address`}>
          Юридический адрес
        </Label>
        <Field
          className={`Col form-control ${
            errors[prefix]
              ? errors[prefix].legal_address
                ? 'is-invalid'
                : ''
              : ''
          }`}
          id={`${prefix}.legal_address`}
          name={`${prefix}.legal_address`}
        />
        <div />
        <div className='invalid-feedback'>
          {errors[prefix] ? errors[prefix].legal_address : ''}
        </div>
      </div>
      <div className='Row form-field'>
        <div className='Col' />
        <div className='Col form-checkbox-container form-check'>
          <Field
            type='checkbox'
            id={`${prefix}.same_mail`}
            name={`${prefix}.same_mail`}
            className='form-check-input'
          />
          <Label for={`${prefix}.same_mail`} className='form-check-label'>
            Почтовый адрес совпадает с юридическим
          </Label>
        </div>
      </div>
      <div className='Row form-field'>
        <Label className='Col' htmlFor={`${prefix}.mailing_address`}>
          Почтовый адрес
        </Label>
        <Field
          className={`Col form-control ${
            errors[prefix]
              ? errors[prefix].mailing_address
                ? 'is-invalid'
                : ''
              : ''
          }`}
          id={`${prefix}.mailing_address`}
          name={`${prefix}.mailing_address`}
          disabled={values[prefix].same_mail}
        />
        <div />
        <div className='invalid-feedback'>
          {errors[prefix] ? errors[prefix].mailing_address : ''}
        </div>
      </div>
      <div className='Row form-field'>
        <Label className='Col' htmlFor={`${prefix}.kpp`}>
          КПП
        </Label>
        <Field
          className={`Col form-control ${
            errors[prefix] ? (errors[prefix].kpp ? 'is-invalid' : '') : ''
          }`}
          id={`${prefix}.kpp`}
          name={`${prefix}.kpp`}
        />
        <div />
        <div className='invalid-feedback'>
          {errors[prefix] ? errors[prefix].kpp : ''}
        </div>
      </div>
      <div className='Row form-field'>
        <Label className='Col' htmlFor={`${prefix}.pc`}>
          Р/с
        </Label>
        <Field
          className={`Col form-control ${
            errors[prefix] ? (errors[prefix].pc ? 'is-invalid' : '') : ''
          }`}
          id={`${prefix}.pc`}
          name={`${prefix}.pc`}
        />
        <div />
        <div className='invalid-feedback'>
          {errors[prefix] ? errors[prefix].pc : ''}
        </div>
      </div>
      <div className='Row form-field'>
        <Label className='Col' htmlFor={`${prefix}.bank`}>
          Банк
        </Label>
        <Field
          className={`Col form-control ${
            errors[prefix] ? (errors[prefix].bank ? 'is-invalid' : '') : ''
          }`}
          id={`${prefix}.bank`}
          name={`${prefix}.bank`}
        />
        <div />
        <div className='invalid-feedback'>
          {errors[prefix] ? errors[prefix].bank : ''}
        </div>
      </div>
      <div className='Row form-field'>
        <Label className='Col' htmlFor={`${prefix}.bic`}>
          БИК
        </Label>
        <Field
          className={`Col form-control ${
            errors[prefix] ? (errors[prefix].bic ? 'is-invalid' : '') : ''
          }`}
          id={`${prefix}.bic`}
          name={`${prefix}.bic`}
        />
        <div />
        <div className='invalid-feedback'>
          {errors[prefix] ? errors[prefix].bic : ''}
        </div>
      </div>
      <div className='Row form-field'>
        <Label className='Col' htmlFor={`${prefix}.kc`}>
          К/с
        </Label>
        <Field
          className={`Col form-control ${
            errors[prefix] ? (errors[prefix].kc ? 'is-invalid' : '') : ''
          }`}
          id={`${prefix}.kc`}
          name={`${prefix}.kc`}
        />
        <div />
        <div className='invalid-feedback'>
          {errors[prefix] ? errors[prefix].kc : ''}
        </div>
      </div>
    </>
  );
}
