import * as yup from 'yup';

const requiredMessage = () => `Поле является обязательным`;

const realSchema = yup.object().shape({
  lastname: yup.string().required(requiredMessage),
  name: yup.string().required(requiredMessage),
  surname: yup.string().required(requiredMessage),
  birthday: yup.date().required(requiredMessage),
  type: yup.number().required(requiredMessage),
});

const legalSchema = yup.object().shape({
  inn: yup.string().required(requiredMessage),
  ogrn: yup.string().required(requiredMessage),
  company: yup.string().required(requiredMessage),
  legal_address: yup.string().required(requiredMessage),
});

const validationSchema = yup.object().shape({
  uid: yup.string().required(requiredMessage),
  case_id: yup.string().required(requiredMessage),
  start: yup.date().required(requiredMessage),
  isPlaintiffLegal: yup.boolean(),
  isDefendantLegal: yup.boolean(),
  plaintiffLegal: yup.object().when('isPlaintiffLegal', {
    is: true,
    then: legalSchema,
  }),
  plaintiffReal: yup.object().when('isPlaintiffLegal', {
    is: false,
    then: realSchema,
  }),
  defendantLegal: yup.object().when('isDefendantLegal', {
    is: true,
    then: legalSchema,
  }),
  defendantReal: yup.object().when('isDefendantLegal', {
    is: false,
    then: realSchema,
  }),
});
export { validationSchema };
