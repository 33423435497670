import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {Input} from 'reactstrap';
import {ITemplateSelect} from './TemplateSelectTypes';
import {RootState} from "store/reducers";
import {setDocuments, setIsEditorLoading, setSelectedItemId, setTemplates} from "store/actions";
import {UNSAVED_UPDATE_MESSAGE} from "constants/app_сonstants";

function restrictSymbols(text: string) {
  return text.length > 52 ? text.slice(0, 52) + '...' : text;
}

function TemplateSelect({
  templates,
  documents,
  selectedItemId,
  setSelectedItemId,
  isTextChanged,
  currentItem,
  isTemplatesEditAllowed,
  editor
}: ITemplateSelect) {

  // Выбирает первый елемент в селекте, если selectedItemId не задан
  useEffect(() => {
    if (!selectedItemId && templates && documents) {
      let firstItemId: number | null = null
      let isTemplate = true

      if (templates.length) {
        firstItemId = templates[0].template_id
        isTemplate = true
      } else if (documents.length) {
        firstItemId = documents[0].id
        isTemplate = false
      } else {
        editor?.setData('')
        setIsEditorLoading(false)
      }
      setSelectedItemId(firstItemId, isTemplate)
    }
  }, [setSelectedItemId, selectedItemId, templates, documents, editor])

  return (
    <div className='d-flex align-items-center'>
      <h4 className='me-3 mb-0 card-title'>Документ:</h4>

      <Input
        type='select'
        className='form-select me-4 doc-file-select'
        disabled={(!templates?.length && !documents?.length) || !isTemplatesEditAllowed}
        value={currentItem?.template_id || (currentItem?.id ? 0 - currentItem.id : 0) || 0}
        onChange={e => {
          let isReload = true
          if (isTextChanged) {
            isReload = window.confirm(UNSAVED_UPDATE_MESSAGE)
          }
          if (isReload) {
            let value = parseInt(e.target.value);
            if (value === 0) {
              setSelectedItemId(null, true)
            } else if (value > 0) {
              setSelectedItemId(value, true)
            } else {
              setSelectedItemId(Math.abs(value), false)
            }
          }
        }}
      >
        {templates && documents && isTemplatesEditAllowed && (
          <>
            {templates && templates.length && (
              <optgroup label='Шаблоны'>
                {templates.map((item, index) => (
                  <option value={item.template_id} key={`${item.title}_${index}`}>
                    {restrictSymbols(item.title)}
                  </option>
                ))}
              </optgroup>
            )}
            {documents && documents.length && (
              <optgroup label='Сохранённые документы'>
                {documents.map((doc, index) => (
                  <option value={0 - doc.id} key={`${doc.name}_${index}`}>
                    {restrictSymbols(doc.name)}
                  </option>
                ))}
              </optgroup>
            )}
          </>
        )}
      </Input>
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  const {templates, documents, selectedItemId, isTextChanged, currentItem} = state.Document;

  return {
    templates,
    documents,
    selectedItemId,
    isTextChanged,
    currentItem,
  };
};

const mapDispatchToProps = {
  setSelectedItemId,
  setDocuments,
  setTemplates,
  setIsEditorLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateSelect);
