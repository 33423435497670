import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { RootState } from 'store/reducers';
import {
  getInfoJudicialHearing,
  getJudicialCaseInfo,
  setActionStatus,
  startTranscribation,
  setIsEditorLoading,
  setSelectedItemId,
  setTemplates,
  setDocuments,
  setPathListener
} from 'store/actions';
import { AudioProcessing } from 'pages';
import {
  getTheFirstLetter,
  parseTime,
  getTimeAsSecond,
  insertBlock
} from 'utils/app_helper';
import { DEFAULT_COLORS } from 'constants/app_сonstants';
import { Breadcrumbs, PlayerMemo } from 'components';
import TranscribationStartButton
  from 'components/Player/components/TranscribationStartButton/TranscribationStartButton';
import EditDoc from './components/EditDoc/EditDoc';
import {
  IPlayerTypes,
  ITranscribationInfo,
  ITranscriptionPage,
} from './TranscriptionPageTypes';
import { getStatusAudio } from 'store/transcription/selectors';
import { withRouter } from 'react-router-dom';

function transcriptionDataToString(
  data: ITranscribationInfo[],
  isTimeShow: boolean = false,
) {
  let text = '';

  for (let paragraph of data) {
    let time = isTimeShow
      ? ` ${parseTime(paragraph.startTime)} - ${parseTime(paragraph.endTime)}`
      : '';

    text += `<p><span style="background:${paragraph.color};"><strong>${paragraph.name}${time}  :</strong></span>  ${paragraph.text}</p>`;
  }

  return text;
}

const TranscriptionPage = ({
  history,
  soundFilePath,
  waveform,
  getInfoJudicialHearing,
  getJudicialCaseInfo,
  setActionStatus,
  startTranscribation,
  transcribationText,
  dataHearing,
  transcribationStatus,
  isPlayerReady,
  soundFileStatus,
  setIsEditorLoading,
  setSelectedItemId,
  setTemplates,
  setDocuments,
  pathListener,
  setPathListener
}: ITranscriptionPage) => {
  const [playerType, setPlayerType] = useState<IPlayerTypes>('mono');
  const [editor, setEditor] = useState<any>(null);
  const [isTranscriptionLoading, setIsTranscriptionLoading] = useState(false);

  const [isTranscribationTime, setIsTranscribationTime] = useState(false);

  const updateParticipantsBlock = useCallback(() => {
    function assignParticipants(transcribationData) {
      return transcribationData?.map(phrase => {
        let phraseTS = getTimeAsSecond(phrase.startTime);
        let phraseTE = getTimeAsSecond(phrase.endTime);

        let speakingPerson = dataHearing?.participants.find(person => {
          const findedItemTime = person.times?.findIndex(
            timeRange =>
              phraseTE <= timeRange.timeEnd && phraseTS >= timeRange.timeStart,
          );

          return (
            (parseInt(person.channel) === parseInt(phrase.channel) &&
              findedItemTime > -1) ||
            parseInt(person.channel) === parseInt(phrase.speakerTag)
          );
        });

        phrase.color =
          speakingPerson?.color === DEFAULT_COLORS[0]
            ? ''
            : speakingPerson?.color;
        phrase.name = speakingPerson
          ? `${speakingPerson.lastname}
        
      ${speakingPerson.name ? getTheFirstLetter(speakingPerson.name) + '.' : ''}
      ${
            speakingPerson.surname
              ? getTheFirstLetter(speakingPerson.surname) + '.'
              : ''
          }`
          : `Канал ${phrase.channel || phrase.speakerTag}`;

        return phrase;
      });
    }

    if (transcribationText && dataHearing) {
      let blockText = assignParticipants(transcribationText);
      blockText = transcriptionDataToString(blockText, isTranscribationTime);
      // if (currentTemplate && dataHearing) {
      //   // let isBlockUpdated = false;
      //   // const defaultTitle = 'Автоматическая транскрибация';
      //   // const templateId = currentTemplate?.template_id;
      //   // const content = new Blob([blockText], { type: 'text/html' });
      //   // Если блок существует обновляем его
      //   // if (currentTemplate?.blocks) {
      //   //   for (let block of currentTemplate.blocks) {
      //   //     if (block.title === 'Автоматическая транскрибация') {
      //   //       updateDocTemplate({
      //   //         id: block.id,
      //   //         title: block.title,
      //   //         templateId: templateId,
      //   //         content: content,
      //   //       });
      //   //       isBlockUpdated = true;
      //   //       break;
      //   //     }
      //   //   }
      //   // }
      //   // // Если блока нет создаём новый
      //   // if (!isBlockUpdated) {
      //   //   addDocTemplate({ templateId, title: defaultTitle, content });
      //   // }
      // }
      return blockText;
    }
  }, [
    transcribationText,
    // updateDocTemplate,
    // addDocTemplate,
    // currentTemplate,
    isTranscribationTime,
    dataHearing,
  ]);

  // Очищает стейт редактора если пользователь покидает со страницу транскрибации
  useEffect(() => {
    if (!pathListener) {
      setPathListener(
        history.listen((location) => {
          if (!location.pathname.includes('/templates/') && !location.pathname.includes('/transcription/')) {
            setSelectedItemId(null, true)
            setTemplates(undefined)
            setDocuments(undefined)
          }
        })
      )
    }
  }, [history, pathListener, setPathListener, setSelectedItemId, setTemplates, setDocuments])

  useEffect(() => {
    getInfoJudicialHearing();
  }, [getInfoJudicialHearing]);

  useEffect(() => {
    getJudicialCaseInfo();
  }, [getJudicialCaseInfo]);

  // Обновляет блок автоматической транскрибации при изменении канала
  useEffect(() => {
    updateParticipantsBlock();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataHearing?.participants]);

  // Обработка ответа транскрибации
  useEffect(() => {
    if (isTranscriptionLoading) {
      if (transcribationStatus === 'done') {
        let updatedContent = updateParticipantsBlock();
        setIsEditorLoading(true)
        insertBlock(
          editor,
          updatedContent,
          (message, status) => {
            setActionStatus({
              message: message,
              status: status,
            });
            setIsEditorLoading(false)
          }
        )
        setIsTranscriptionLoading(false);
      } else if (transcribationStatus === 'error') {
        setActionStatus({
          message: 'Произошла ошибка транскрибации',
          status: 'error',
        });
        setIsTranscriptionLoading(false);
      }
    }
  }, [
    isTranscriptionLoading,
    transcribationStatus,
    editor,
    setActionStatus,
    updateParticipantsBlock,
    setIsEditorLoading
  ]);

  function handleTranscriptionStart() {
    startTranscribation();
    setIsTranscriptionLoading(true);
  }

  return (
    <>
      <Breadcrumbs
        title={'Аудиозапись'}
        breadcrumbItems={['Дела судебного участка', 'Данные']}
      />
      <AudioProcessing
        isPlayerReady={isPlayerReady}
        soundFileStatus={soundFileStatus}
      >
        <div
          className={`page-title-box page-heading ${
            playerType === 'hide' ? 'folded' : ''
          }`}
        >
          {playerType === 'hide' && <div />}
          <div className="player-container">
            <PlayerMemo
              waveform={waveform}
              soundFilePath={soundFilePath}
              playerType={playerType}
              setPlayerType={setPlayerType}
            >
              <TranscribationStartButton
                handleTranscriptionStart={handleTranscriptionStart}
                setIsTranscribationTime={setIsTranscribationTime}
              />
            </PlayerMemo>
          </div>
          {playerType === 'hide' ? (
            <>
              <div />
              <Button
                color="primary"
                className="fold-button btn btn-outline-primary d-flex align-items-center player-fold__button justify-content-center w-lg control__channel-button"
                onClick={() => setPlayerType('mono')}
              >
                Развернуть плеер
              </Button>
              <TranscribationStartButton
                handleTranscriptionStart={handleTranscriptionStart}
                setIsTranscribationTime={setIsTranscribationTime}
              />
            </>
          ) : null}
        </div>
      </AudioProcessing>
      <br />
      <EditDoc editor={editor} setEditor={setEditor} />
    </>
  );
};

const mapStatetoProps = (state: RootState) => {
  const { soundFilePath, transcribationText, transcribationStatus, waveform } =
    state.Transcription;
  const { dataHearing } = state.JudicialHearing;
  const { isPlayerReady } = state.Transcription;
  const soundFileStatus = getStatusAudio(state);
  const { pathListener } = state.Document

  return {
    soundFilePath,
    transcribationText,
    dataHearing,
    transcribationStatus,
    waveform,
    isPlayerReady,
    soundFileStatus,
    pathListener
  };
};
export default connect(mapStatetoProps, {
  getInfoJudicialHearing,
  getJudicialCaseInfo,
  setActionStatus,
  startTranscribation,
  setIsEditorLoading,
  setSelectedItemId,
  setTemplates,
  setDocuments,
  setPathListener
})(withRouter(TranscriptionPage));
