import React from 'react';
import {connect} from "react-redux";
import { IBlockButton } from './BlockButtonTypes';
import {insertBlock} from "utils/app_helper";
import {setIsEditorLoading} from "store/actions";

function BlockButton({
  editor,
  item,
  isEdit,
  handleDeleteBlockClick,
  handleEditBlockClick,
}: IBlockButton) {
  return (
    <button
      onClick={() => {
        if (!isEdit) {
          insertBlock(editor, item.content, setIsEditorLoading);
        }
      }}
      className={`editor-sidebar__button ${isEdit ? 'edit' : 'add'}`}
    >
      <div className='editor__sidebar_block_title_wrapper'>
        <i className='bx bx-add-to-queue' />
        <span className='editor__block_title'>{item.title}</span>
      </div>
      {isEdit && (
        <div className='block__edit-icons'>
          <i
            className='fas fa-pen'
            onClick={() => handleEditBlockClick(item)}
          />
          <i
            className='fas fa-trash-alt'
            onClick={() => {
              // @ts-ignore
              handleDeleteBlockClick(item);
            }}
          />
        </div>
      )}
    </button>
  );
}

export default connect(() => ({}), {
  setIsEditorLoading
})(BlockButton)