export const ITEMS_FORM = {
  title: ' Создать дело',
  alterTitle: 'Изменить дело',

  topFirstBlock: {
    inputs: [
      { text: 'УИД', name: 'uid', type: 'text' },
      { text: 'Номер', name: 'case_id', type: 'string' },
    ],
  },

  topSecondBlock: {
    inputs: [{ text: 'Дата:', name: 'start', type: 'date', bold: true }],
  },
  legalEntityBlock: {
    inputs: [
      { text: 'ИНН', name: 'inn', type: 'number' },
      { text: 'ОГРН', name: 'ogrn', type: 'number' },
      { text: 'Название компании', name: 'company', type: 'text' },
      { text: 'Юридический адрес', name: 'legal_address', type: 'text' },
      {
        text: ' ',
        name: 'isQualityMails',
        type: 'checkbox',
        subText: 'Почтовый адрес совпадает с юридическим',
      },
      { text: 'Почтовый адрес', name: 'mailing_address', type: 'text' },
      { text: 'КПП', name: 'kpp', type: 'string' },
      { text: 'Р/c', name: 'pc', type: 'number' },
      { text: 'Банк', name: 'bank', type: 'text' },
      { text: 'БИК', name: 'bic', type: 'number' },
      { text: 'К/с', name: 'kc', type: 'number' },
    ],
  },
  realEntityBlock: {
    inputs: [
      {
        text: ' Фамилия',
        name: 'lastname',
        type: 'text',
        subText: 'Юридическое лицо',
      },
      { text: 'Имя', name: 'name', type: 'text' },
      { text: 'Отчество', name: 'surname', type: 'text' },
      { text: 'Дата рождения', name: 'birthday', type: 'date' },
      { text: 'Адрес Регистрации', name: 'reg_address', type: 'text' },
      {
        text: 'Адрес проживания',
        name: 'res_address',
        type: 'text',
        subText: 'Почтовый адрес совпадает с юридическим',
      },
      { text: 'Номер телефона', name: 'phone', type: 'tel' },
    ],
  },
};

export const INITIAL_CASE_DATA_REAL = {
  id: '',
  person_type: 'individual',
  name: '',
  lastname: '',
  surname: '',
  birthday: '',
  reg_address: '',
  res_address: '',
  phone: '',
  same_mail: false,
  type: 0,
};

export const INITIAL_CASE_DATA_LEGAL = {
  id: '',
  person_type: 'legal_entity',
  inn: '',
  ogrn: '',
  company: '',
  legal_address: '',
  mailing_address: '',
  kpp: '',
  pc: '',
  bank: '',
  bic: '',
  kc: '',
  same_mail: false,
  type: 1,
};
