import React, { useState } from 'react';
import { connect } from 'react-redux';
import { updateJudicialCases } from 'store/actions';

import editImg from 'assets/images/courtCases/edit.svg';
import ModalWindow from '../../ModalWindow/ModalWindow';
import { IJudicialCasesItem } from 'store/judicialCases/types';

interface IJudicialcasesTableEditBtnTypes {
  areaId;
  currentItem: any;
  updateJudicialCases: (item: IJudicialCasesItem, areaId: number) => {};
}

const JudicialСasesTableEditBtn = ({
  areaId,
  currentItem,
  updateJudicialCases,
}: IJudicialcasesTableEditBtnTypes) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const modalUpdateHandler = (item: IJudicialCasesItem) => {
    return updateJudicialCases(item, areaId);
  };

  return (
    <>
      <div
        className='courtcases-table-editbtn-wrapp p-2'
        onClick={() => setIsOpenModal(true)}
        style={{ cursor: 'pointer' }}
      >
        <img src={editImg} alt='Редактировать' />
      </div>
      <ModalWindow
        isOpen={isOpenModal}
        closeHandler={() => setIsOpenModal(false)}
        areaId={areaId}
        caseData={currentItem}
        submitHandler={modalUpdateHandler}
      />
    </>
  );
};

const mapStateToProps = state => {
  const { areaId } = state.Profile;
  return { areaId };
};

export default connect(mapStateToProps, {
  updateJudicialCases,
})(JudicialСasesTableEditBtn);
