import {TextEditor} from 'components';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {addDocument} from 'store/actions';
import TemplateSelect from '../TemplateSelect/TemplateSelect';
import htmlDocx from 'html-docx-js/dist/html-docx';
import { RootState } from 'store/reducers';
import {
  fetchDocTemplate,
  fetchDocTemplates,
  updateDocument,
  deleteDocument,
  setSelectedItemId,
  setIsAutoSaveOn
} from 'store/document/actions';
import SaveModalDoc from '../SaveDocModal/SaveDocModal';
import {getCaseAndHearingData} from 'store/judicialHearing/selectors';
import {IEditDoc} from './EditDocTypes';
import {HTML_ENCODING} from 'constants/app_сonstants';
import {Button} from 'reactstrap';
import {Prompt} from "react-router";
import {UNSAVED_UPDATE_MESSAGE} from "constants/app_сonstants";
import DeleteTemplateModal from "pages/EditTemplates/components/DeleteTemplateModal/DeleteTemplateModal";

// Функция для загрузки blob файла

function downloadBlob(blob, name = 'file.txt') {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement('a');

  // Set link's href to point to the Blob URL
  link.href = blobUrl;
  link.download = name;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  // Remove link from body
  document.body.removeChild(link);
}

const EditDoc = ({
  caseAndHearingData,
  userId,
  templates,
  selectedItemId,
  fetchDocTemplates,
  addDocument,
  editor,
  setEditor,
  currentItem,
  isTextChanged,
  isCurrentItemTemplate,
  updateDocument,
  deleteDocument,
  isTemplateId,
  pages,
  isEditorLoading,
  isAutoSaveOn,
  setIsAutoSaveOn,
}: IEditDoc) => {
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<'delete' | false>(false);

  useEffect(() => {
    function beforeunloadHandler(event) {
      if (isTextChanged) {
        event.returnValue = UNSAVED_UPDATE_MESSAGE;
      }
    }

    window.addEventListener('beforeunload', beforeunloadHandler);

    return () => {
      window.removeEventListener('beforeunload', beforeunloadHandler)
    }
  }, [isTextChanged, editor])

  useEffect(() => {
    if (userId) {
      fetchDocTemplates(userId);
    }
  }, [fetchDocTemplates, userId]);

  // Автосохранение документа
  useEffect(() => {
    function autoSaveDocument() {
      if (
        isAutoSaveOn
        && !isCurrentItemTemplate
        && editor
        && !isEditorLoading
        && isTextChanged
        && selectedItemId
      ) {
        updateDocument({
          ...currentItem,
          content: editor?.getData()
        });
      }
    }

    let autoSaveInterval = setInterval(autoSaveDocument, 15000)
    return () => {
      clearInterval(autoSaveInterval)
    }
  }, [
    updateDocument,
    isCurrentItemTemplate,
    isAutoSaveOn,
    isEditorLoading,
    editor,
    currentItem,
    selectedItemId,
    isTextChanged
  ])

  function getFileName() {
    if (caseAndHearingData) {
      return `Дело № ${caseAndHearingData.case_id}. Заседание от ${caseAndHearingData.date} ${caseAndHearingData.time}`;
    } else {
      return `${currentItem?.title}`;
    }
  }

  async function exportToWord() {
    let htmlString = HTML_ENCODING + '<body>' + editor?.getData() + '</body>';
    const fileBuffer = htmlDocx.asBlob(htmlString);

    await downloadBlob(fileBuffer, getFileName() + '.docx');
  }

  function saveFile(fileName) {
    const date = new Date().getMilliseconds();

    addDocument({
      file: editor?.getData(),
      name: `${fileName} ${date}.html`,
      type: selectedItemId || 0,
    });
  }

  const isTemplatesEditAllowed = Boolean(pages?.includes('templates'))

  return (
    <div className='row mb-4'>
      <Prompt
        message={UNSAVED_UPDATE_MESSAGE}
        when={isTextChanged}
      />
      <div className='col--lg-12'>
        <div className='card'>
          <div className='card-body'>
            <div className='editor__controls mb-4'>
              <div className='editor__controls__left'>
                <TemplateSelect isTemplatesEditAllowed={isTemplatesEditAllowed} editor={editor} />
                {isTemplateId ? (
                  <Link
                    to={`/${currentItem ? 'templates' : 'documents'}/edit/${
                      currentItem?.id || currentItem?.template_id
                    }`}
                    className={`${
                      (!templates?.length &&
                      caseAndHearingData?.documents?.length < 1) ||
                      !isTemplatesEditAllowed
                        ? 'disabled'
                        : ''
                    }`}
                  >
                    <Button
                      color='primary'
                      className='w-md d-flex justify-content-center'
                      disabled={
                        (!templates?.length &&
                        caseAndHearingData?.documents?.length < 1) ||
                        !isTemplatesEditAllowed
                      }
                    >
                      Редактировать
                    </Button>
                  </Link>
                ) : (
                  <div>
                    <Button
                      color='primary'
                      className='w-md d-flex justify-content-center'
                      disabled={
                        (!templates?.length &&
                        caseAndHearingData?.documents?.length < 1) ||
                        !isTemplatesEditAllowed
                      }
                      onClick={() => {
                        setIsDeleteModalOpen('delete')
                      }}
                    >
                      Удалить
                    </Button>
                  </div>
                )}
                <Link to={'/templates/add'} className={!isTemplatesEditAllowed ? 'disabled': ''}>
                  <Button
                    color='primary'
                    className='w-lg d-flex justify-content-center'
                    disabled={!isTemplatesEditAllowed}
                  >
                    Добавить шаблон
                  </Button>
                </Link>
              </div>
              <div className='editor__buttons-container'>
                <button
                  className='btn btn-primary'
                  onClick={() => {
                    if (isCurrentItemTemplate || !currentItem) {
                      setIsSaveModalOpen(true);
                    } else {
                      updateDocument({
                        ...currentItem,
                        content: editor?.getData()
                      });
                    }
                  }}
                  disabled={!isTemplatesEditAllowed || isEditorLoading}
                >
                  Сохранить документ
                </button>
                <button
                  className='btn btn-primary export-button'
                  onClick={exportToWord}
                  disabled={isEditorLoading}
                >
                  Экспорт в word
                </button>
              </div>
            </div>
            <TextEditor
              initialText={currentItem?.content || ''}
              handleDeleteBlockClick={false}
              blocks={currentItem?.blocks}
              editor={editor}
              setEditor={setEditor}
              isAutoSaveOn={isAutoSaveOn}
              setIsAutoSaveOn={setIsAutoSaveOn}
            />
          </div>
        </div>
      </div>
      <SaveModalDoc
        caseAndHearingData={caseAndHearingData}
        selectedDoc={currentItem?.title}
        getFileName={getFileName}
        isSaveModalOpen={isSaveModalOpen}
        setIsSaveModalOpen={setIsSaveModalOpen}
        saveFile={saveFile}
      />
      <DeleteTemplateModal
        condition={isDeleteModalOpen ? 'delete' : false}
        setIsModalOpen={setIsDeleteModalOpen}
        itemName={currentItem?.name || ''}
        itemId={currentItem?.id}
        isBlock={false}
        isTemplate={false}
        deleteHandler={deleteDocument}
      />
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  const {userId, pages} = state.Profile;
  const {
    templates,
    currentItem,
    selectedItemId,
    isTextChanged,
    isCurrentItemTemplate,
    isTemplateId,
    isEditorLoading,
    isAutoSaveOn
  } = state.Document;
  const caseAndHearingData: any = getCaseAndHearingData(state);

  return {
    userId,
    templates,
    currentItem,
    caseAndHearingData,
    selectedItemId,
    isTextChanged,
    isCurrentItemTemplate,
    isTemplateId,
    pages,
    isEditorLoading,
    isAutoSaveOn
  };
};

const mapDispatchToProps = {
  addDocument,
  fetchDocTemplate,
  fetchDocTemplates,
  updateDocument,
  deleteDocument,
  setSelectedItemId,
  setIsAutoSaveOn,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDoc);
