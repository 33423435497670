import React from "react";
import Flatpickr from 'react-flatpickr';
import {Russian} from 'flatpickr/dist/l10n/ru.js';
import "flatpickr/dist/themes/light.css";
import {connect} from "react-redux";
import {updateJudicialCases} from "store/judicialCases/actions";
import {
  ICaseEndingDateDropdown
} from "./CaseEndingDateDropdownTypes";
import {getDateInFormat} from "utils/app_helper";

function CaseEndingDateDropdown({currentItem, areaId, updateJudicialCases}: ICaseEndingDateDropdown) {
  function handleApplyClick(date) {
    if (date?.length) {
      updateJudicialCases({...currentItem, end: getDateInFormat(date) || ''}, areaId, false)
    }
  }

  return currentItem.end?.length ? (
    <div>{currentItem.end}</div>
  ) : (
    <div className='d-flex justify-content-center align-items-stretch gap-2'>
      <Flatpickr
        onChange={handleApplyClick}
        className='form-control d-block'
        placeholder="Закрыть дело"
        tag='span'
        options={{
          altInput: false,
          dateFormat: "d.m.y",
          locale: Russian,
        }}
      />
    </div>
  )
}

const mapStateToProps = state => {
  const {areaId} = state.Profile;
  return {areaId};
};

export default connect(mapStateToProps, {
  updateJudicialCases,
})(CaseEndingDateDropdown);