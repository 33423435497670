import React, {useCallback, useEffect, useState} from 'react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import {autocompletePhrases} from 'constants/autocomplete_phrases';
import BlocksControl from './BlocksControl/BlocksControl';
import {ITextEditorTypes} from './TextEditorTypes';
import {RootState} from 'store/reducers';
import {getDataHearing} from 'store/judicialHearing/selectors';
import {connect} from 'react-redux';
import {Spinner} from "reactstrap";
import {setIsEditorLoading, setIsTextChanged} from "store/actions";

function toFeedObj(phrase, initiateSymbol, endOfStr = '') {
  return {id: initiateSymbol + phrase, text: phrase + endOfStr};
}

function TextEditor({
  initialText,
  handleDeleteBlockClick,
  handleEditBlockClick,
  blocks,
  editor,
  setEditor,
  hearingData,
  setIsEditorLoading,
  isEditorLoading,
  setIsTextChanged,
  isTextChanged,
  currentItem,
  isAutoSaveOn,
  setIsAutoSaveOn,
  isCurrentItemTemplate
}: ITextEditorTypes) {
  const [isEditorInitializing, setIsEditorInitializing] = useState(false)
  const setEditorData = useCallback((text) => {

    function insertText(text) {
      if (editor) {
        editor.model.change(writer => {
          const insertPosition = writer.createPositionAt(editor.model.document.getRoot(), 'end');
          const viewFragment = editor.data.processor.toView(text);
          const modelFragment = editor.data.toModel(viewFragment);
          writer.append(modelFragment, insertPosition)
        })
      }
    }

    // Если текст слишком длинный раделяем его на части и вствляем в редактор частями
    // (нужно чтобы браузер не зависал во время вставки и появился лоадер)
    if (editor && currentItem) {
      setIsEditorLoading(true)
      if (initialText.length > 100000) {
        const splitText = text.match(/.{1,50000}.+?<\/p>/g)
        editor.setData('')
        // @ts-ignore
        for (let dataPart of splitText) {
          setTimeout(() => {
            insertText(dataPart)
          }, 0)
        }
      } else {
        setTimeout(() => {
          editor.setData(text)
        }, 0)
      }
      setTimeout(() => {
        setIsEditorLoading(false)
        setIsTextChanged(false)
      }, 0)
    }
  }, [initialText, editor, setIsEditorLoading, currentItem, setIsTextChanged])

  useEffect(() => {
    if (!editor && !isEditorInitializing) {
      setIsEditorInitializing(true)
      Editor
        .create(document.querySelector('#editor'), {
          mention: {
            feeds: [
              {
                marker: '"',
                feed: hearingData
                  ? Object.values(hearingData?.participants).map(person =>
                    toFeedObj(
                      `${person.lastname} ${person.name} ${person.surname}`,
                      '"',
                      ': ',
                    ),
                  )
                  : [],
                minimumCharacters: 1,
                dropdownLimit: 4,
              },
              {
                marker: '!',
                feed: autocompletePhrases.map(phrase =>
                  toFeedObj(phrase, '!'),
                ),
                minimumCharacters: 1,
                dropdownLimit: 6,
              },
            ],
          },
          fontSize: {
            options: [8, 10, 12, 14, 16, 18, 22, 24, 26],
          },
        })
        .then(editor => {
          const toolbarContainer = document.querySelector('#toolbar-container');
          if (toolbarContainer) {
            toolbarContainer.appendChild(editor.ui.view.toolbar.element);
          }
          setEditor(editor)
          setEditorData(initialText)
          setIsEditorInitializing(false)
        })
        .catch(error => {
          console.error(error)
        })
    }
  }, [editor, hearingData, initialText, setEditor, setEditorData, isEditorInitializing, setIsEditorInitializing])

  useEffect(() => {
    setEditorData(initialText)
  }, [initialText, setEditorData])

  useEffect(() => {
    if (editor) {
      if (editor && isEditorLoading) {
        editor.enableReadOnlyMode('my-feature-id');
      } else {
        editor.disableReadOnlyMode('my-feature-id')
      }
    }
  }, [isEditorLoading, editor])

  useEffect(() => {
    function changeHandler() {
      if (!isEditorLoading) {
        setIsTextChanged(true)
      }
    }

    if (editor?.model && !isTextChanged) {
      editor.model.document.on('change:data', changeHandler)
    }

    return () => {
      editor?.model.document.off('change:data', changeHandler)
    }
  }, [isEditorLoading, isTextChanged, editor, setIsTextChanged])

  return (
    <>
      <div className='toolbar-container-outer'>
        <div id='toolbar-container' />
        {setIsAutoSaveOn &&
          <div className="form-check form-switch autosave-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="autosaveSwitch"
              checked={isAutoSaveOn}
              onChange={(e) => {
                setIsAutoSaveOn(e.target.checked)
              }}
              disabled={isCurrentItemTemplate || !currentItem}
              title='Автосохранение'
            />
            <label className="form-check-label" htmlFor="autosaveSwitch">Автосохранение</label>
          </div>
        }
      </div>
      <div className='row-editor'>
          <div className={`editor-container ${isEditorLoading ? 'editor-loading' : ''}`}>
            {isEditorLoading &&
              <div className='editor-outer__spinner-container'>
                <Spinner className='editor-outer__spinner' color="dark"/>
              </div>
            }
            <div id='editor' />
          </div>
        <BlocksControl
          editor={editor}
          handleDeleteBlockClick={handleDeleteBlockClick}
          handleEditBlockClick={handleEditBlockClick}
          blocks={blocks}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state: RootState) => {
  const hearingData = getDataHearing(state);
  const {isTextChanged, isEditorLoading, currentItem, isCurrentItemTemplate} = state.Document

  return {
    hearingData,
    isEditorLoading,
    isTextChanged,
    currentItem,
    isCurrentItemTemplate
  };
};

export default connect(mapStateToProps, {
  setIsEditorLoading,
  setIsTextChanged
})(TextEditor);
