import {IDocTemplate, IDocTemplateBlock, IDocument, IDocumentOrTemplate, IDocumentToAdd} from './types';

export enum ActionType {
  FETCH_DOC_TEMPLATES = 'FETCH_DOC_TEMPLATES',
  FETCH_DOC_TEMPLATE = 'FETCH_DOC_TEMPLATE',
  SET_SELECTED_ITEM_ID = 'SET_SELECTED_ITEM_ID',
  SET_CURRENT_ITEM = 'SET_CURRENT_ITEM',
  ADD_TEMPLATE = 'ADD_TEMPLATE',
  UPDATE_TEMPLATE = 'UPDATE_TEMPLATE',
  DELETE_TEMPLATE = 'DELETE_TEMPLATE',
  SET_TEMPLATES = 'SET_TEMPLATES',
  SET_DOCUMENTS = 'SET_DOCUMENTS',
  ADD_DOCUMENT = 'ADD_DOCUMENT',
  UPDATE_DOCUMENT = 'UPDATE_DOCUMENT',
  DELETE_DOCUMENT = 'DELETE_DOCUMENT',
  ADD_BLOCK = 'ADD_BLOCK',
  UPDATE_BLOCK = 'UPDATE_BLOCK',
  DELETE_BLOCK = 'DELETE_BLOCK',
  CLEAR_BLOCKS = 'CLEAR_BLOCK',
  SET_IS_TEXT_CHANGED = 'SET_IS_TEXT_CHANGED',
  SET_IS_EDITOR_LOADING = 'SET_IS_EDITOR_LOADING',
  SET_IS_AUTOSAVE_ON = 'SET_IS_AUTOSAVE_ON',
  SET_PATH_LISTENER = 'SET_PATH_LISTENER'
}

// Общее
export interface ISetSelectedItemId {
  type: ActionType.SET_SELECTED_ITEM_ID;
  payload: {id: number, isTemplate: boolean}
}

export interface ISetCurrentItem {
  type: ActionType.SET_CURRENT_ITEM;
  payload: {item: IDocumentOrTemplate, isTemplate: boolean};
}

// Шаблоны

export interface IFetchDocTemplates {
  type: ActionType.FETCH_DOC_TEMPLATES;
  payload?: any;
}
export interface IFetchDocTemplate {
  type: ActionType.FETCH_DOC_TEMPLATE;
  payload: number;
}

export interface IAddTemplate {
  type: ActionType.ADD_TEMPLATE;
  payload: IDocumentOrTemplate;
}

export interface IUpdateTemplate {
  type: ActionType.UPDATE_TEMPLATE;
  payload: IDocTemplate;
}

export interface IDeleteTemplate {
  type: ActionType.DELETE_TEMPLATE;
  payload: number;
}

export interface IAddBlock {
  type: ActionType.ADD_BLOCK;
  payload: IDocTemplateBlock;
}

export interface IUpdateBlock {
  type: ActionType.UPDATE_BLOCK;
  payload: IDocTemplateBlock;
}

export interface IDeleteBlock {
  type: ActionType.DELETE_BLOCK;
  payload: number;
}

export interface ISetTemplates {
  type: ActionType.SET_TEMPLATES;
  payload: IDocTemplate[] | undefined;
}

export interface IClearBlocks {
  type: ActionType.CLEAR_BLOCKS;
}

// Документы
export interface IAddDocument {
  type: ActionType.ADD_DOCUMENT;
  payload: IDocumentToAdd;
}

export interface IUpdateDocument {
  type: ActionType.UPDATE_DOCUMENT;
  payload: IDocument;
}

export interface IDeleteDocument {
  type: ActionType.DELETE_DOCUMENT;
  payload: number;
}

export interface ISetDocuments {
  type: ActionType.SET_DOCUMENTS;
  payload: IDocument[];
}

export interface ITextChanged {
  type: ActionType.SET_IS_TEXT_CHANGED;
  payload: boolean;
}

export interface ISetIsEditorLoading {
  type: ActionType.SET_IS_EDITOR_LOADING;
  payload: boolean;
}

export interface ISetIsAutoSaveOn {
  type: ActionType.SET_IS_AUTOSAVE_ON;
  payload: boolean;
}

export interface ISetPathListener {
  type: ActionType.SET_PATH_LISTENER;
  payload: Function;
}

export type Action =
  | IFetchDocTemplates
  | IFetchDocTemplate
  | ISetSelectedItemId
  | ISetCurrentItem
  | IAddTemplate
  | IUpdateTemplate
  | IDeleteTemplate
  | IAddDocument
  | IUpdateDocument
  | IDeleteDocument
  | ISetDocuments
  | ISetTemplates
  | IAddBlock
  | IUpdateBlock
  | IDeleteBlock
  | IClearBlocks
  | ITextChanged
  | ISetIsEditorLoading
  | ISetIsAutoSaveOn
  | ISetPathListener
  ;
