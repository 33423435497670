import {ActionType} from './actionTypes';
import {IDocTemplate, IDocTemplateAdd, IDocTemplateBlock, IDocument, IDocumentToAdd} from "store/document/types";

// Общее
export const setSelectedItemId = (id: number | null, isTemplate: boolean) => ({
  type: ActionType.SET_SELECTED_ITEM_ID,
  payload: {id, isTemplate}
})

export const setCurrentItem = (item: IDocTemplate | IDocument | undefined, isTemplate: boolean) => ({
  type: ActionType.SET_CURRENT_ITEM,
  payload: {item, isTemplate}
})

// Шаблоны
export const addTemplate = (data : IDocTemplate) => ({
  type: ActionType.ADD_TEMPLATE,
  payload: data,
});

export const updateTemplate = (data: IDocTemplateAdd) => ({
  type: ActionType.UPDATE_TEMPLATE,
  payload: data,
});

export const addBlock = (data: IDocTemplateBlock) => ({
  type: ActionType.ADD_BLOCK,
  payload: data,
})

export const updateBlock = (data: IDocTemplateBlock) => ({
  type: ActionType.UPDATE_BLOCK,
  payload: data,
})

export const deleteBlock = (id: number) => ({
  type: ActionType.DELETE_BLOCK,
  payload: id,
})

export const clearBlocks = () => ({
  type: ActionType.CLEAR_BLOCKS
})

export const deleteTemplate = (id: number) => ({
  type: ActionType.DELETE_TEMPLATE,
  payload: id,
});

export const fetchDocTemplates = (id: any = null) => ({
  type: ActionType.FETCH_DOC_TEMPLATES,
  payload: id,
});

export const fetchDocTemplate = (id: number) => ({
  type: ActionType.FETCH_DOC_TEMPLATE,
  payload: id,
});

export const setTemplates = (data: IDocTemplate[] | undefined) => ({
  type: ActionType.SET_TEMPLATES,
  payload: data,
})

// Документы
export const setDocuments = (data: IDocument[] | undefined) => ({
  type: ActionType.SET_DOCUMENTS,
  payload: data,
})

export const addDocument = (data: IDocumentToAdd) => ({
  type: ActionType.ADD_DOCUMENT,
  payload: data
})

export const updateDocument = (data: IDocument) => ({
  type: ActionType.UPDATE_DOCUMENT,
  payload: data
})

export const deleteDocument = (id: number) => ({
  type: ActionType.DELETE_DOCUMENT,
  payload: id
})

export const setIsTextChanged = (status: boolean) => ({
  type: ActionType.SET_IS_TEXT_CHANGED,
  payload: (status)
})

export const setIsEditorLoading = (status: boolean) => ({
  type: ActionType.SET_IS_EDITOR_LOADING,
  payload: status
})

export const setIsAutoSaveOn = (status: boolean) => ({
  type: ActionType.SET_IS_AUTOSAVE_ON,
  payload: status
})

export const setPathListener = (listener: Function) => ({
  type: ActionType.SET_PATH_LISTENER,
  payload: listener
})