import {createSelector} from 'reselect';
import {RootState} from 'store/reducers';

const getState = (rootState: RootState) => {
  return rootState.Document;
};

export const getIsTemplate = createSelector(
  getState,
  (state: any) => state.isTemplateId
)

export const getSelectedItemId = createSelector(
  getState,
  (state: any) => state.selectedItemId,
);

export const getCreatedBlocks = createSelector(
  getState,
  (state: any) => state.createdBlocks
)