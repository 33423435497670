export const LEFT_TEXT = 'Судебный участок № ';

export const ITEMS_PROFILE_MENU = [
  // {
  //   link: '/profile',
  //   text: 'Профиль',
  //   icon: 'bx-user',
  //   isDetach: false,
  //   appearance: 'none',
  // },
  {
    link: '/logout',
    text: 'Выход',
    icon: 'bx-power-off',
    isDetach: false,
    appearance: 'danger',
  },
];
