import { Action, ActionType } from './actionTypes';
import { ITranscriptionDocuments } from './types';

function getAutoSaveFormLocalStorage() {
  let isAutoSave = localStorage.getItem('isAutoSaveOn')
  if (isAutoSave) {
    return JSON.parse(isAutoSave) === true
  } else {
    return true
  }
}

const initState: ITranscriptionDocuments = {
  templates: undefined,
  documents: undefined,
  currentItem: undefined,
  isCurrentItemTemplate: undefined,
  selectedItemId: null,
  isTemplateId: undefined,
  createdBlocks: [],
  isTextChanged: false,
  isEditorLoading: false,
  isAutoSaveOn: getAutoSaveFormLocalStorage(),
  pathListener: undefined
};

const Document = (state = initState, action: Action) => {
  switch (action.type) {
    case ActionType.SET_SELECTED_ITEM_ID:
      return {
        ...state,
        selectedItemId: action.payload.id,
        isTemplateId: action.payload.isTemplate,
        isEditorLoading: Boolean(action.payload.id)
      };

    case ActionType.SET_CURRENT_ITEM:
      return {
        ...state,
        currentItem: action.payload.item,
        isCurrentItemTemplate: Boolean(action.payload.item?.template_id),
        createdBlocks: action.payload.item?.blocks || [],
        isTextChanged: false
      };

    case ActionType.ADD_DOCUMENT:
      return {
        ...state,
        isTextChanged: false
      }

    case ActionType.SET_DOCUMENTS:
      return {
        ...state,
        documents: action.payload
      }

    case ActionType.SET_TEMPLATES:
      return {
        ...state,
        templates: action.payload
      }

    case ActionType.ADD_BLOCK:
      return {
        ...state,
        createdBlocks: [...state.createdBlocks, action.payload],
        isTextChanged: true
      }

    case ActionType.UPDATE_BLOCK:
      return {
        ...state,
        createdBlocks: state.createdBlocks.map(block => block.id === action.payload.id ? action.payload : block),
        isTextChanged: true
      }

    case ActionType.DELETE_BLOCK:
      return {
        ...state,
        createdBlocks: state.createdBlocks.filter(block => block.id !== action.payload),
        isTextChanged: true
      }

    case ActionType.CLEAR_BLOCKS:
      return {
        ...state,
        createdBlocks: []
      }

    case ActionType.DELETE_TEMPLATE:
      return {
        ...state,
        templates: state.templates?.filter(template => template.template_id !== action.payload),
      }

    case ActionType.SET_IS_TEXT_CHANGED:
      return {
        ...state,
        isTextChanged: action.payload
      }

    case ActionType.SET_IS_EDITOR_LOADING:
      return {
        ...state,
        isEditorLoading: action.payload
      }

    case ActionType.UPDATE_DOCUMENT:
      return {
        ...state,
        isTextChanged: false
      }

    case ActionType.SET_IS_AUTOSAVE_ON:
      localStorage.setItem('isAutoSaveOn', JSON.stringify(action.payload))

      return {
        ...state,
        isAutoSaveOn: action.payload
      }

    case ActionType.SET_PATH_LISTENER:
      return {
        ...state,
        pathListener: action.payload
      }

    default:
      return state;
  }
};

export default Document;
